import * as React from "react";
import { Center, Container, Heading, Text, VStack } from "@chakra-ui/react";

import { GenericInternalLink, Seo } from "../components";

import { paths } from "../paths";

const NotFoundPage = () => {
  return (
    <>
      <Seo
        title="Page not found | Basilium"
        description="Basilium - minimalist nutrition and weight tracker with a low-carb focus. Perfect for Keto, Paleo, Primal, and other low-carb diets."
        robots="noindex, follow"
      />
      <Container>
        <Center minH="100vh">
          <VStack>
            <Heading fontSize="100px" as="h1" color="brand.primary">
              404
            </Heading>
            <Text>Sorry, there isn't a page here.</Text>
            <GenericInternalLink
              to={paths.home}
              textDecoration="underline"
              _hover={{
                textDecoration: "underline",
                color: "brand.primary",
              }}
            >
              Go to the home page?
            </GenericInternalLink>
          </VStack>
        </Center>
      </Container>
    </>
  );
};

export default NotFoundPage;
